<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat tile>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-airplane-edit</v-icon>
          COORDINACION: {{ fechaCoordSel }}
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-btn
          v-if="hcoordActiva == 1"
          small
          color="green"
          dark
          @click="abrirCoordinacion(selHcoordId)"
          ><v-icon small left>mdi-door-open</v-icon>abrir</v-btn
        >
        <v-btn
          v-if="hcoordActiva == 0"
          small
          color="green"
          dark
          @click="cerrarCoordinacion(selHcoordId)"
          ><v-icon small left>mdi-close</v-icon>cerrar</v-btn
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-col cols="3">
          <v-select
            label="Coordinaciones Activas"
            :items="listaHcoord"
            small
            item-value="HCOORD_ID"
            item-text="HCOORD_FECHA"
            v-model="selHcoordId"
            @change="seleccionarFechaCoordAbierta(selHcoordId)"
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaCoord"
                v-on="on"
                label="Coordinaciones Cerradas"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="selFecha"
              :reactive="true"
              v-model="fechaCoord"
              :allowed-dates="getFechasCoord"
              :max="new Date().toISOString().substr(0, 10)"
              min="2004-01-01"
              elevation="2"
              locale="es-ec"
              :close-on-content-click="true"
              @change="seleccionarFechaCoordCerrada"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-btn small @click="dialogHcoord = true" color="info" class="mx-2"
            ><v-icon left>mdi-plus</v-icon>Nueva Coordinación</v-btn
          >
        </v-col>
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="px-2 mx-4 pr-6 py-4">
            <v-data-table
              :headers="headerAl"
              :options.sync="options"
              :items="hcoordAl"
              :itemsPerPage="itemsPerPageAl"
              :server-items-length="totalItemsAl"
              class="elevation-1"
              hide-default-footer
            ></v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="px-1 pr-4 py-4">
            <v-data-table
              :headers="headerGuias"
              :options.sync="options"
              :items="hcoordGuias"
              :itemsPerPage="itemsPerPageGuia"
              :server-items-length="totalItemsGuia"
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:item.opciones="{ item }">
                <v-btn
                  v-if="item.ESTADO == 'ABIERTA'"
                  target="_blank"
                  small
                  title="coordinar"
                  text
                  :to="`coordinacion_guia_aerea/${item.AWB}`"
                  ><v-icon small>mdi-eye</v-icon></v-btn
                >
                <v-btn
                  target="_blank"
                  small
                  title="Editar guía"
                  text
                  :to="`editar_guia_aerea/${item.AWB}`"
                  ><v-icon color="green" small>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                  v-if="item.ESTADO == 'RESERVADA'"
                  target="_blank"
                  small
                  title="Abrir guía"
                  text
                  @click="confirmarAbrirGuia(item.AWB)"
                  ><v-icon color="green" small>mdi-door-open</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogHcoord" width="600" scrollable>
      <v-card>
        <v-toolbar color="blue" height="40" dark flat>
          <v-toolbar-title>COORDINACIONES</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small text dark @click="dialogHcoord = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row class="mx-0 px-0 py-0 my-0">
          <v-col cols="6" class="mx-2 px-2">
            <v-text-field
              type="date"
              v-model="nuevaFechaCoord"
              label="Fecha coordinacion"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="mx-2 px-2 pt-4 mt-4">
            <v-btn
              title="Crear nueva coordinación"
              small
              dark
              color="green"
              @click="guardarCoordinacion"
              ><v-icon small left>mdi-content-save</v-icon>Agregar
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-0 px-0 py-0 my-0">
          <v-col cols="6" class="ml-2 pl-2 py-0 my-0">
            <v-menu
              v-model="hcBuscarFechas"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  clearable
                  v-on="on"
                  v-model="dateRangeText"
                  label="Desde - hasta"
                ></v-text-field>
              </template>
              <v-date-picker
                elevation="2"
                locale="es-ec"
                :close-on-content-click="true"
                @change="hcBuscarFechas = false"
                v-model="hcFechas"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- <v-col cols="3" class="mx-1 px-1 py-0 my-0">
            <v-text-field v-model="hasta" label="hasta"></v-text-field>
          </v-col> -->
          <v-col cols="5" class="mx-1 px-1 pt-1 mt-1">
            <v-checkbox
              @change="soloActivos = !soloActivos"
              :v-model="!soloActivos"
              label="Activos"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="mx-1 px-1 py-0 my-0">
            <v-data-table
              :items="soloActivos == true ? listaHcoord : listaCoordCerradas"
              :headers="headerHcoord"
            >
              <template v-slot:item.opcionesHc="{ item }">
                <v-btn
                  text
                  color="red"
                  small
                  title="borrar"
                  @click="borrarCoordinacion(item)"
                  ><v-icon small center>mdi-delete</v-icon></v-btn
                >
                <v-btn
                  v-if="item.HCOORD_ACTIVO == 0"
                  text
                  color="green"
                  small
                  title="cerrar"
                  @click="cerrarCoordinacion(item)"
                  ><v-icon small center>mdi-close</v-icon></v-btn
                >
                <v-btn
                  v-if="item.HCOORD_ACTIVO == 1"
                  text
                  color="green"
                  small
                  title="abrir"
                  @click="abrirCoordinacion(item)"
                  ><v-icon small center>mdi-door-open</v-icon></v-btn
                >
              </template>
              <template v-slot:item.HCOORD_ACTIVO="{ item }">
                {{ item.HCOORD_ACTIVO == 0 ? "ACTIVA" : "CERRADA" }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ListaHcoord",
  components: {},
  props: {},

  data: () => ({
    options: {},
    rail: true,
    drawer: null,
    overlay: false,
    datosForm: {
      HCOORD_ID: "",
      HCOORD_FECHA: "",
      HCOORD_ACTIVO: "",
    },
    listaHcoord: [],
    listaAutocomplete: [],
    seleccion: [],

    selHcoordId: "",

    itemsPerPage: 50,
    totalItems: 10,
    currentPage: 1,
    pageCount: 0,

    hcoordAl: [],
    hcoordGuias: [],
    itemsPerPageAl: 50,
    totalItemsAl: 10,
    itemsPerPageGuia: 50,
    totalItemsGuia: 10,
    headerHcoord: [
      { text: "opciones", value: "opcionesHc" },
      { text: "ID", value: "HCOORD_ID" },
      { text: "FECHA", value: "HCOORD_FECHA" },
      { text: "ESTADO", value: "HCOORD_ACTIVO" },
    ],
    headerAl: [
      { text: "Aerolínea", value: "AL_NOMBRE" },
      { text: "Coordinado", value: "CAJAS_COORD" },
      { text: "Recibido", value: "CAJAS_REC" },
      { text: "Confirmado", value: "CAJAS_CONF" },
    ],
    headerGuias: [
      { text: "Opcion", value: "opciones" },
      { text: "Awb", value: "AWB" },
      { text: "Cliente", value: "CLIENTE_NOMBRE", width: 240 },
      { text: "Aerolínea", value: "AL_NOMBRE", width: 200 },
      { text: "Destino", value: "CIUPA_COD_CIUDAD" },
      { text: "Estado", value: "ESTADO" },
    ],
    menu: false,
    requiredRules: [(v) => !!v || "This field is required"],
    fechaCoord: "",
    listaCoordCerradas: [],
    fechasHabilitadas: [],
    fechaCoordSel: "",
    hcoordActiva: "",
    dialogHcoord: false,

    ///dialog hcoord
    soloActivos: false,
    nuevaFechaCoord: "",
    desde: "",
    hasta: "",
    hcFechas: [],
    hcBuscarFechas: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
    dateRangeText() {
      return this.hcFechas.join(" - ");
    },
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-hcoord");
      this.requestApi({
        method: "GET",
        data: {
          todo: "0",
        },
      })
        .then((res) => {
          this.listaHcoord = res.data.hcoord;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarDatosHcoord(hcoordId) {
      this.setLoadingTable(true);
      this.setUrl("api/datos-hcoord");
      this.requestApi({
        method: "GET",
        data: {
          hcoord_id: hcoordId,
        },
      })
        .then((res) => {
          this.hcoordAl = res.data.al_coord;
          this.hcoordGuias = res.data.guias_coord;
          //this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    confirmarAbrirGuia(awbNum) {
      Vue.swal({
        html: "Desea abrir esta guía ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/abrir-guia");
          this.requestApi({
            method: "POST",
            data: {
              awb: awbNum,
            },
          }).then((res) => {
            console.log(res);
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },

    cargarCoordCerradas() {
      this.setUrl("api/lista-hcoord");
      this.requestApi({
        method: "GET",
        data: {
          todo: "1",
        },
      })
        .then((res) => {
          this.listaCoordCerradas = res.data.hcoord;
          //console.log(this.listaCoordCerradas);
          this.fechasHabilitadas = [];
          for (let i = 0; i < this.listaCoordCerradas.length; i++) {
            this.fechasHabilitadas.push(
              this.listaCoordCerradas[i].HCOORD_FECHA
            );
          }
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    getFechasCoord(val) {
      if (this.fechasHabilitadas.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    seleccionarFechaCoordAbierta() {
      this.fechaCoordSel = this.listaHcoord.HCOORD_FECHA;

      for (let i = 0; this.listaHcoord.length; i++) {
        if (this.listaHcoord[i].HCOORD_ID == this.selHcoordId) {
          this.fechaCoordSel = this.listaHcoord[i].HCOORD_FECHA;
          this.hcoordActiva = this.listaHcoord[i].HCOORD_ACTIVO;
          break;
        }
      }

      this.cargarDatosHcoord(this.selHcoordId);
      this.fechaCoord = "";
    },
    seleccionarFechaCoordCerrada() {
      for (let i = 0; this.listaCoordCerradas.length; i++) {
        if (this.listaCoordCerradas[i].HCOORD_FECHA == this.fechaCoord) {
          this.selHcoordId = this.listaCoordCerradas[i].HCOORD_ID;
          this.hcoordActiva = this.listaCoordCerradas[i].HCOORD_ACTIVO;
          break;
        }
      }
      this.fechaCoordSel = this.fechaCoord;
      this.cargarDatosHcoord(this.selHcoordId);
      this.menu = false;
    },
    borrarCoordinacion(hcId) {
      this.setUrl("api/borrar-hcoord");
      this.requestApi({
        method: "POST",
        data: {
          hcoord_id: hcId,
        },
      })
        .then((res) => {
          this.cargarDatos();
          this.cargarCoordCerradas();
          this.alertNotification({ param: { html: res.data.msg } });
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarCoordinacion() {
      this.setUrl("api/crear-hcoord");
      this.requestApi({
        method: "POST",
        data: {
          HCOORD_FECHA: this.nuevaFechaCoord,
        },
      })
        .then((res) => {
          this.fechaCoord = "";
          this.cargarDatos();
          this.alertNotification({ param: { html: res.data.msg } });
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cerrarCoordinacion(hcId) {
      this.setUrl("api/cerrar-hcoord");
      this.requestApi({
        method: "POST",
        data: {
          hcoord_id: hcId,
        },
      })
        .then((res) => {
          this.fechaCoord = "";
          this.selHcoordId = "";
          this.selFecha = "";
          this.fechaCoordSel = "";
          this.hcoordAl = [];
          this.hcoordGuias = [];
          this.cargarDatos();
          this.cargarCoordCerradas();
          this.alertNotification({ param: { html: res.data.msg } });
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    abrirCoordinacion(hcId) {
      this.setUrl("api/abrir-hcoord");
      this.requestApi({
        method: "POST",
        data: {
          hcoord_id: hcId,
        },
      })
        .then((res) => {
          this.fechaCoord = "";
          this.selHcoordId = hcId;
          this.hcoordActiva = 0;
          this.cargarDatos();
          this.cargarCoordCerradas();
          this.alertNotification({ param: { html: res.data.msg } });
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    handlePageChange() {},
  },

  mounted() {
    this.cargarDatos();
    this.cargarCoordCerradas();
  },
};
</script>
